<!--公共头部栏-->
<template>
  <div>
    <header class="top-bar flex-start bd-b bg-white">
      <van-icon :name="icon" class="font-xxl" @click="visible = !visible" />
      <h3 class="flex-grow text-c ml-1x text-1">{{ userInfo.merchantName }}</h3>
    </header>

    <header class="placeholder"></header>

    <!-- 下滑进入 -->
    <div class="menu-action" :class="{ visible }">
      <div class="me flex-start">
        <van-image
          round
          width="1.6rem"
          height="1.6rem"
          :src="defaultImg"
          class="flex-shrink"
        />
        <div class="flex-around flex-col info flex-grow">
          <div class="res flex-between">
            <h4>{{ userInfo.userName }}</h4>
            <p class="blue" @click="show = true">
              <span class="text-v mr-x">修改密码</span>
              <van-icon name="arrow" class="text-v" />
            </p>
          </div>
          <p>{{ userInfo.phone | safeShow }}</p>
          <p v-if="userInfo.accountNo">{{ userInfo.accountNo }}</p>
        </div>
      </div>

      <van-cell-group class="mt-2x">
        <van-cell title="切换景区" is-link url="/#/login/SelectScenic" />
        <van-cell title="体验新版" is-link @click="toNew" />
      </van-cell-group>

      <!--退出登录-->
      <footer class="footer">
        <van-button type="primary" block @click="logout">退出登录</van-button>
      </footer>
    </div>

    <!--修改密码-->
    <change-pass-word :show.sync="show" :userInfo="userInfo"></change-pass-word>
  </div>
</template>

<script>
import { findUserDetail, logout } from "../../api/user";
import { phoneFormat, defaultImg } from "../../utils/global";
import ChangePassWord from "../../views/login/components/ChangePassWord";
export default {
  name: "TopBar",
  components: {
    ChangePassWord,
  },
  data() {
    return {
      defaultImg,
      visible: false,
      userInfo: {},
      show: false,
    };
  },
  computed: {
    icon() {
      return this.visible ? "cross" : "wap-nav";
    },
  },
  created() {
    this.loadData();
  },
  filters: {
    safeShow: phoneFormat,
  },
  methods: {
    // 获取用户信息
    loadData() {
      findUserDetail({
        userId: localStorage.userId,
      })
        .then((res) => {
          res.ifSetPwd = true;
          this.userInfo = res;
        })
        .catch(() => {});
    },
    // 退出登录
    logout() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要退出登录吗？",
        })
        .then(() => {
          logout()
            .then(() => {
              this.$toast.success("已退出登录");
            })
            .catch(() => {})
            .finally(() => {
              localStorage.removeItem("toke");
              localStorage.removeItem("account");
              this.$router.push("/login");
            });
        })
        .catch(() => {});
    },
    // 体验新版
    toNew() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要切换到新版吗？系统将会刷新！",
        })
        .then(() => {
          localStorage.setItem("newVersion", "true");
          location.reload();
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style scoped lang="less">
.top-bar {
  position: fixed;
  height: 1rem;
  left: 0;
  top: 0;
  right: 0;
  padding: 0 0.24rem;
  z-index: 500;
}

.placeholder {
  height: 1rem;
}

.menu-action {
  position: fixed;
  top: -100vh;
  left: 0;
  right: 0;
  bottom: 100vh;
  background: #fff;
  z-index: 100;
  transition: all 0.3s ease-in-out;
  padding: 0.32rem;
  &.visible {
    top: 1rem;
    bottom: 0;
  }
  .me {
    .info {
      margin-left: 0.32rem;
      align-items: start;
      height: 1.6rem;
    }
  }
  .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
