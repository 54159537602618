<!--门票核销-->
<template>
  <div>
    <scan-and-card :active="active" @ok="loadTicket" ref="card" />

    <!--核销成功-->
    <van-dialog v-model="show">
      <div class="text-c box">
        <van-icon name="checked" size="1rem" class="primary" />
        <p class="font-xl mt-2x">{{ productName }}核销成功</p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import ScanAndCard from "./ScanAndCard";
import { orderTicketverify } from "../../../../api/search";
import { handTicketCheck } from "../../../../api/order";
import { speak } from "../../../../utils/hardware";
export default {
  name: "TicketOff",
  components: { ScanAndCard },
  props: {
    // tab类型 0身份证 1 扫描
    active: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: false,
      productName: "",
      colorType: 0, //0正常绿色 1红色
    };
  },
  methods: {
    loadTicket(code) {
      orderTicketverify({
        keyword: code,
      })
        .then((res) => {
          if (res.length > 1) {
            this.$router.push({
              path: "/home/TicketOff",
              query: { code },
            });
          } else if (res.length === 1) {
            handTicketCheck({
              code: res[0].auxiliaryId,
            })
              .then((res) => {
                //核销成功
                if (res.code === 200) {
                  this.productName = res.msg;
                  // this.$toast(this.productName + " 核销成功");
                  speak(this.productName);
                  // this.show = true;
                  this.colorType = 0;
                  this.$emit("getInfo", res); //给主组件返回计数数据
                  this.$refs.card.clear();
                } else {
                  //核销失败
                  speak(res.msg);
                  this.colorType = 1;
                  this.$emit("myerr", res);
                  this.$toast(res.msg);
                  // this.$refs.card.readyIsOk();
                }
                this.$emit("getColorType", this.colorType);
                // this.$refs.card.readyIsOk();
              })
              .catch((res) => {
                this.colorType = 1;
                this.$emit("getColorType", this.colorType);
                this.$emit("myerr", res);
                speak(res.message ? res.message : res[0].productName);
                this.$refs.card.readyIsOk();
              });
          } else {
            this.colorType = 1;
            this.$toast("没有查询到相关门票");
            speak("没有查询到相关门票");
            this.$emit("getColorType", this.colorType);
            // this.$refs.card.readyIsOk();
          }
        })
        .catch((res) => {
          this.colorType = 1;
          speak(res.message ? res.message : "核销失败");
          this.$emit("getColorType", this.colorType);
        });
    },
  },
};
</script>

<style scoped></style>
