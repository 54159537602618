<!--项目核销-->
<template>
  <div>
    <header class="text-c mv-1x flex-center" @click="show = true">
      <h4 class="text-1">{{ merchantItem.merchantName }}</h4>
      <van-icon name="play" class="font-l arrow" />
    </header>
    <!--项目列表-->
    <ul class="list flex-start" v-show="projectList.length">
      <li
        @click="linkTo(item)"
        class="item flex-between flex-col shadow"
        v-for="item in projectList"
        :key="item.spuId"
      >
        <h4 class="text-2">{{ item.name }}</h4>
        <p class="font-s" v-show="item.deductionType === 0">按次扣费</p>
        <p class="font-s" v-show="item.deductionType === 1">计时扣费</p>
        <p class="font-s" v-show="item.deductionType === 2">手工扣费</p>
      </li>
    </ul>

    <van-empty description="暂无数据" v-show="!projectList.length" />

    <!--选择商户弹窗-->
    <van-dialog
      v-model="show"
      title="选择商户"
      show-cancel-button
      :show-confirm-button="false"
      :before-close="beforeClose"
    >
      <div class="content">
        <van-cell
          class="mt-2x"
          v-for="item in merchantList"
          :title="item.merchantName"
          :key="item.id"
          @click="checkItem(item)"
        >
          <!-- 使用 right-icon 插槽来自定义标题 -->
          <template #right-icon>
            <van-icon
              name="success"
              class="primary font-xxl"
              v-show="item.checked"
            />
          </template>
        </van-cell>

        <p class="p-2x gray text-c" v-show="merchantList.length === 0">
          —— 暂无商户 ——
        </p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { windowUserManageMerchantList } from "../../../../api/merchant";
import { merchantProject } from "../../../../api/product";

export default {
  name: "TabItemOff",
  data() {
    return {
      show: false,
      merchantId: "",
      merchantList: [],
      merchantItem: {},
      projectList: [],
    };
  },
  created() {
    this.loadMerchant();
  },
  methods: {
    // 加载商户列表
    loadMerchant() {
      windowUserManageMerchantList()
        .then(({ amusementMerchantList = [] }) => {
          amusementMerchantList.forEach((item) => {
            item.checked = false;
          });
          this.merchantList = amusementMerchantList;
          if (this.merchantList.length === 1) {
            this.merchantList[0].checked = true;
            this.merchantItem = this.merchantList[0];
            this.$store.commit("common/setSaleMerchant", {
              saleMerchantId: this.merchantList[0].id,
              saleMerchantName: this.merchantList[0].merchantName,
            });
            this.loadItem();
          } else {
            this.show = true;
          }
        })
        .catch(() => {});
    },
    // 加载项目列表
    loadItem() {
      merchantProject()
        .then((res) => {
          this.projectList = res;
          this.show = false;
        })
        .catch(() => {});
    },
    // 选择商户
    checkItem(item) {
      this.merchantList.forEach((item) => {
        item.checked = false;
      });
      item.checked = true;
      this.merchantItem = item;
      this.$store.commit("common/setSaleMerchant", {
        saleMerchantId: item.id,
        saleMerchantName: item.merchantName,
      });
      this.loadItem();
    },
    // 关闭前
    beforeClose(action, done) {
      const item = this.merchantList.find((temp) => temp.checked);
      if (item) {
        done();
      } else {
        this.$toast.fail("已取消");
        this.$emit("cancel", 0);
        done(false);
      }
    },
    // 跳转
    linkTo(item) {
      this.$router.push({
        path: "/home/ItemOff",
        query: {
          skuId: item.skuId,
          spuId: item.spuId,
          name: item.name,
          deductionType: item.deductionType,
          merchantName: this.merchantItem.merchantName,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.arrow {
  transform: rotateZ(90deg);
  margin-left: 0.08rem;
}
.list {
  margin: 0.16rem 0;
  padding-left: 0.32rem;
  padding-top: 0.32rem;
  background: #fff;
  .item {
    flex-shrink: 0;
    width: 2.073rem;
    height: 2rem;
    padding: 0.32rem 0.16rem;
    border: 1px solid @primary;
    border-radius: 0.16rem;
    margin-right: 0.32rem;
    margin-bottom: 0.32rem;
    color: @primary;
  }
}

.content {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
