<template>
  <div>
    <!-- 核销数据列表 -->
    <van-tabs v-model="listActive" @change="tabChange">
      <van-tab title="今日核销明细">
        <div class="list mt-1x" v-if="offList.length">
          <div
            class="p-2x mb-1x list-item"
            v-for="item in offList"
            :key="item.id"
          >
            <p>票号：{{ item.mainAuxiliaryId }}</p>
            <p>{{ item.productName }}</p>
            <p>核销时间：{{ item.writeOffTime | date("YYYY-MM-DD hh:mm") }}</p>
          </div>
        </div>
        <div class="etmy" v-else>
          <img :src="etmyUrl" alt="" />
          <p class="gray">暂无数据</p>
        </div>
      </van-tab>
      <van-tab title="历史核销明细">
        <van-row class="search">
          <van-col @click="typeFn" span="4">
            {{ typeTitle }} <van-icon name="arrow-down" />
          </van-col>
          <van-col @click="isSearch" span="16" v-if="typeTitle == '时间'">
            {{ dateName }}
          </van-col>
          <van-col span="16" v-else>
            <input
              class="ipt"
              type="text"
              v-model="keyword"
              placeholder="请输入票号/单号"
            />
          </van-col>
          <van-col span="4" @click="isSearch">
            <img :src="typeTitle == '时间' ? riliUrl : searchUrl" alt="" />
          </van-col>
        </van-row>
        <div class="list mt-1x" v-if="writeOffListOld.length">
          <div
            class="p-2x mb-1x list-item"
            v-for="item in writeOffListOld"
            :key="item.id"
          >
            <p>票号：{{ item.mainAuxiliaryId }}</p>
            <p>{{ item.productName }}</p>
            <p>核销时间：{{ item.writeOffTime | date("YYYY-MM-DD hh:mm") }}</p>
          </div>
        </div>
        <div class="etmy" v-else>
          <img :src="etmyUrl" alt="" />
          <p class="gray">暂无数据</p>
        </div>
        <ActionSheet v-model="show" :actions="actions" @select="onSelect" />
      </van-tab>
    </van-tabs>
    <van-calendar
      :min-date="new Date('2022-1-1')"
      :max-date="new Date()"
      v-model="isShowTime"
      type="range"
      allow-same-day
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import { toDaywriteOffList } from "@/api/order";
import { ActionSheet } from "vant";
import { getOldVerificationrecord } from "@/api/search";
export default {
  name: "OffList",
  components: { ActionSheet },
  data() {
    return {
      listActive: 0,
      offList: [], // 今日核销数据
      show: false,
      actions: [
        { name: "时间", id: 0 },
        { name: "单号", id: 1 },
      ],
      typeTitle: "时间",
      dateName: "请选择时间搜索",
      riliUrl: require("../../../../assets/images/rili@2x.png"),
      searchUrl: require("../../../../assets/images/ss@2x.png"),
      etmyUrl: require("../../../../assets/images/emty.png"),
      keyword: "", // 查询单号
      isShowTime: false,
      writeOffPageOld: {
        page: 1, // 请求页码
        size: 10, // 请求页码当前显示多少数据
        total: 0, // 订单总数
      },
      writeOffListOld: [], // 历史核销数据
      isPage: false,
    };
  },
  created() {
    this.getToDaywriteOffList();
  },
  methods: {
    // 当前点击的是哪个 核销记录
    tabChange(item) {
      // 当历史核销记录时 产生分页
      item === 1
        ? window.addEventListener("scroll", this.loMoreOld)
        : window.removeEventListener("scroll", this.loMoreOld);
    },
    //格式化日期 开始日期
    formatDate(date) {
      return date.getTime();
    },
    // 格式化日期  结束日期格式化
    formatDateend(date) {
      return date.getTime() + 24 * 60 * 60 * 1000 - 1;
    },
    // 转化 年月日字符串
    newDates(time) {
      const date = new Date(time);
      const Y = date.getFullYear() + "-";
      const M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      const D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + M + D;
    },
    // 确认查询时间
    onConfirm(date) {
      this.writeOffPageOld.page = 1;
      const [start, end] = date;
      this.verifyBeginTime = this.formatDate(start);
      this.verifyEndTime = this.formatDateend(end);
      this.dateName = this.newDates(start) + "~" + this.newDates(end);
      this.getOldVerificationrecord();
      this.isShowTime = false;
    },
    // 点击切换筛选条件
    onSelect(item) {
      this.typeTitle = item.name;
      this.verifyBeginTime = "";
      this.verifyEndTime = "";
      this.dateName = "请选择时间搜索";
      this.show = false;
    },
    // 点击搜索
    isSearch() {
      if (this.typeTitle == "时间") {
        this.isShowTime = true;
      } else {
        if (!this.keyword.length) return;
        this.getOldVerificationrecord();
      }
    },
    typeFn() {
      this.show = true;
    },
    // 历史核销分页
    loMoreOld() {
      let scrollTops =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (
        document.documentElement.offsetHeight <=
        scrollTops + window.innerHeight + 1
      ) {
        if (
          this.writeOffPageOld.total <=
          this.writeOffPageOld.size * this.writeOffPageOld.page
        ) {
          this.$toast("暂无数据");
        } else {
          this.writeOffPageOld.page += 1;
          this.isPage = !this.isPage;
          this.getOldVerificationrecord(this.isPage);
        }
      }
    },
    // 查询历史核销记录
    async getOldVerificationrecord(isPage) {
      const res = await getOldVerificationrecord({
        page: this.writeOffPageOld.page,
        size: this.writeOffPageOld.size,
        verifyBeginTime: this.verifyBeginTime,
        verifyEndTime: this.verifyEndTime,
        keyword: this.keyword,
      });
      this.writeOffPageOld.total = res.total;
      if (isPage) {
        this.isPage = false;
        this.writeOffListOld = this.writeOffListOld.concat(res.records);
      } else {
        this.writeOffListOld = res.records;
      }
    },
    // 查询今日核销数据
    getToDaywriteOffList() {
      toDaywriteOffList().then((res) => {
        this.offList = res.writeOffDatas;
      });
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.loMoreOld);
  },
};
</script>

<style lang="less" scoped>
.list {
  width: 100%;
  .list-item {
    background-color: #fff;
  }
}
.search {
  background-color: #fff;
  width: 95%;
  height: 34px;
  line-height: 32px;
  border-radius: 6px;
  border-radius: 6px;
  margin: 10px auto;
  border: 1px solid #e2e2e2;
  text-align: center;
  img {
    width: 20px;
    vertical-align: middle;
  }
}
.ipt {
  border: none;
}
.etmy {
  text-align: center;
  padding: 20px;
}
</style>
