<!--门票核销与项目核销-->
<template>
  <div>
    <!--top-->
    <top-bar />
    <!--tabs-->
    <van-tabs v-model="active" sticky offset-top="1rem" @change="tabChange">
      <van-tab title="身份证核销">
        <tab-ticket-off
          :active="active"
          @getInfo="getInfo"
          @myerr="myerr"
          @getColorType="getColorType"
        />
      </van-tab>
      <van-tab title="扫描核销">
        <tab-ticket-off
          :active="active"
          @getInfo="getInfo"
          @myerr="myerr"
          @getColorType="getColorType"
        />
      </van-tab>
      <van-tab title="项目消费">
        <tab-item-off @cancel="cancelMerchant" />
      </van-tab>
    </van-tabs>
    <!--辅助提示-->
    <div
      class="bg-white ph-3x pv-2x mt-1x font-l flex-wrap"
      style="line-height: 2"
      v-if="detail.otherMsg && detail.otherMsg.length"
    >
      <div
        class="flex-start flex-wrap"
        v-for="item in detail.otherMsg"
        :key="item"
        :style="{ color: colorType ? 'red' : 'orange' }"
      >
        <!-- 0正常绿色 1红色 -->
        {{ item || "-" }}
      </div>
    </div>
    <div class="bg-white ph-3x pv-2x mt-1x font-l" style="line-height: 2">
      <div class="flex-between">
        <div class="flex-start">
          <p class="bold">临时计数：</p>
          <p>{{ temporaryNum }} 人</p>
        </div>
        <p class="primary" @click="clean">清零</p>
      </div>
    </div>
    <div class="bg-white ph-3x pv-2x mt-1x font-l" style="line-height: 2">
      <div class="flex-start">
        <p class="bold">检票总人数：</p>
        <p>
          {{
            detail.data && detail.data.totalCheckNum
              ? detail.data.totalCheckNum
              : 0
          }}
          人
        </p>
      </div>
    </div>
    <OffList ref="offList" v-if="active !== 2" />
  </div>
</template>

<script>
import TopBar from "../../../components/common/TopBar";
import TabTicketOff from "./components/TabTicketOff";
import TabItemOff from "./components/TabItemOff";
import { formFindScenicDailyPersonTotal } from "../../../api/report";
import OffList from "./components/OffList.vue";

export default {
  name: "ChangeOff",
  components: { TopBar, TabTicketOff, TabItemOff, OffList },
  data() {
    return {
      active: 0,
      checked: "",
      saleMerchantId: "",
      temporaryNum: 0, //临时计数
      detail: { data: { totalCheckNum: 0 } },
      colorType: 0, //0正常绿色 1红色
    };
  },
  activated() {
    this.getInfoItem(); //初始获取检票总人数
  },
  created() {
    let active = sessionStorage.getItem("ChangeOffTabActive");
    this.temporaryNum = localStorage.temporaryNum || 0;
    if (active) this.active = parseInt(active);
  },
  methods: {
    //colorType: 0, //0正常绿色 1红色
    getColorType(colorType) {
      this.colorType = colorType;
    },
    // 核销失败
    myerr(val) {
      // val.otherMsg = val.otherMsg.replace(new RegExp(/( )/g), "\n");
      val.otherMsg = val.otherMsg.split(" "); //内容按空格切割
      if (val.otherMsg.length > 1) {
        //但日期合起来
        val.otherMsg[val.otherMsg.length - 2] =
          val.otherMsg[val.otherMsg.length - 2] +
          " " +
          val.otherMsg[val.otherMsg.length - 1];
        val.otherMsg.splice(val.otherMsg.length - 1, 1);
      }
      this.detail = val;
    },
    // 核销成功
    getInfo(val) {
      val.otherMsg = val.otherMsg.split(" ");
      this.detail = val;
      this.temporaryNum++;
      localStorage.setItem("temporaryNum", this.temporaryNum);
      setTimeout(() => {
        this.$refs.offList.getToDaywriteOffList();
      }, 500);
    },
    getInfoItem() {
      //初始获取数量
      formFindScenicDailyPersonTotal().then((res) => {
        this.detail.data.totalCheckNum = res.checkNum;
      });
    },
    clean() {
      this.temporaryNum = 0;
      localStorage.setItem("temporaryNum", 0);
    },
    // tab点击
    tabChange() {
      sessionStorage.setItem("ChangeOffTabActive", this.active);
    },
    // 点击项目核销-取消商户选择-返回门票核销
    cancelMerchant() {
      this.active = 0;
      this.tabChange();
    },
  },
};
</script>

<style scoped></style>
