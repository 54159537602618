<template>
  <div>
    <van-nav-bar
      fixed
      placeholder
      :title="merchantName"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />

    <!--项目名称-->
    <header class="text-c mv-1x flex-center" @click="show = true">
      <h4 class="text-1">{{ name }}</h4>
      <van-icon name="play" class="font-l arrow" />
    </header>

    <!--开始计时和结束计时-->
    <van-tabs
      v-model="timeType"
      type="card"
      class="mt-1x"
      v-if="deductionType === 1"
    >
      <van-tab title="开始计时" :name="0"></van-tab>
      <van-tab title="结束计时" :name="1"></van-tab>
    </van-tabs>

    <!--手工扣费-->
    <header class="box" v-if="deductionType === 2">
      <div class="input-box flex-start">
        <input
          v-model="consumerPrice"
          type="text"
          class="input flex-grow"
          placeholder="请输入本次消费价格"
          maxlength="100"
        />

        <van-icon
          name="clear"
          class="font-xxl close price-close"
          v-show="consumerPrice.length"
          @click="consumerPrice = ''"
        />
      </div>
    </header>

    <!--扫码-->
    <scan-and-card
      ref="card"
      placeholder="票号/卡或腕带号/身份证/会员编号"
      :cardAndScan="true"
      @ok="onOk"
    />

    <!--切换项目弹窗-->
    <van-dialog
      v-model="show"
      title="切换项目"
      :show-confirm-button="false"
      show-cancel-button
    >
      <div class="wrapper">
        <van-cell-group>
          <van-cell
            v-for="item in projectList"
            :title="item.name"
            :key="item.id"
            :label="type[item.deductionType]"
            @click="checkItem(item)"
          >
            <!-- 使用 right-icon 插槽来自定义标题 -->
            <template #right-icon>
              <van-icon
                name="success"
                class="primary text-v font-xxl"
                v-show="item.checked"
              />
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </van-dialog>

    <!--会员余额扣费-->
    <van-dialog
      v-model="passwordFormVisible"
      title="请输入会员支付密码"
      :show-confirm-button="false"
    >
      <van-form @submit="onSubmit">
        <p class="ph-2x mt-2x">会员姓名：{{ memberInfo.name }}</p>
        <p class="ph-2x mv-2x">会员手机号：{{ memberInfo.mobile }}</p>
        <van-field
          v-model="password"
          type="password"
          name="支付密码"
          label="支付密码"
          placeholder="请输入支付密码"
          :rules="[
            { required: true, message: '请输入密码' },
            { pattern: /^\d{6}$/, message: '请输入6位纯数字密码' },
          ]"
        />
        <div class="flex-between box">
          <van-button
            round
            native-type="button"
            class="flex-grow mr-2x"
            @click="passwordFormVisible = false"
            >取消</van-button
          >
          <van-button
            round
            type="primary"
            native-type="submit"
            class="flex-grow"
            >提交</van-button
          >
        </div>
      </van-form>
    </van-dialog>

    <!--消费通知-->
    <van-dialog
      v-model="show2"
      title="提示"
      :confirm-button-text="`确定（${count}s）`"
      @close="clearCount"
    >
      <div class="box">
        <div class="flex-start mb-2x text-c">
          <van-icon
            name="passed"
            class="primary font-xxl mr-1x"
            v-show="success"
          />
          <van-icon name="close" class="red font-xxl mr-1x" v-show="!success" />
          <h3>{{ info.title }}</h3>
        </div>
        <p class="mt-1x" v-show="info.ticketInfo">
          门票：{{ info.ticketInfo }}
        </p>
        <p class="mt-1x" v-show="info.beginTime">
          开始时间：{{ info.beginTime | date("yyyy-mm-dd hh:mm") }}
        </p>
        <p class="mt-1x" v-show="info.depositInfo">
          押金：{{ info.depositInfo | money }}
        </p>
        <p class="mt-1x" v-show="info.entTime">
          结束时间：{{ info.entTime | date("yyyy-mm-dd hh:mm") }}
        </p>
        <p class="mt-1x red" v-show="info.playDuration">
          游玩时长：{{ info.playDuration }}
        </p>
        <p class="mt-1x red" v-show="info.receivable">
          应收：{{ info.receivable | money }}
        </p>
        <p class="mt-1x" v-show="info.receivableInfo">
          收费详情：{{ info.receivableInfo }}
        </p>
        <p class="mt-1x" v-show="info.assets">剩余：{{ info.assets }}</p>
        <p class="mt-1x" v-show="info.fail">失败说明：{{ info.fail }}</p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import ScanAndCard from "./components/ScanAndCard";
import { itemConsumerMember, itemWriteoff } from "../../../api/order";
import { speak } from "../../../utils/hardware";
import { merchantProject } from "../../../api/product";
import { fix2Reg, RechargeMoney } from "../../.././utils/global";
export default {
  name: "ItemOff",
  components: { ScanAndCard },
  data() {
    return {
      RechargeMoney,
      num: "", // 门票号|微信支付条形码|支付宝付条形码|会员号|项目套餐号
      show: false, // 切换项目弹窗
      show1: false, // 展示会员支付密码弹窗
      show2: false, // 消费弹窗
      projectList: [],
      skuId: "",
      spuId: "",
      consumerPrice: "", // 消费价格
      name: "", // 项目名称
      deductionType: "", // 消费类型
      merchantName: "", // 商户名称
      passwordFormVisible: false, // 需要输入会员密码弹窗
      memberInfo: {}, // 会员信息
      timeType: 0, // 计时类型：开始0 结束1

      success: true, // 消费状态
      info: {}, // 消费信息
      timer: null,
      count: 5,
      type: {
        0: "按次扣费",
        1: "计时扣费",
        2: "手工扣费",
      },

      password: "", // 会员支付密码
    };
  },
  created() {
    const { skuId, spuId, name, deductionType, merchantName } =
      this.$route.query;
    this.skuId = skuId;
    this.spuId = spuId;
    this.name = name;
    this.deductionType = parseInt(deductionType);
    this.merchantName = merchantName;
    this.loadItem();
  },
  methods: {
    // 加载项目列表
    loadItem() {
      merchantProject()
        .then((res) => {
          this.projectList = res.map((item) => {
            item.checked = item.skuId == this.skuId;
            return item;
          });
        })
        .catch(() => {});
    },
    // 返回
    onClickLeft() {
      this.$router.back();
    },
    // 支付密码弹窗
    beforeClose() {},
    // 确定
    onOk(num, password) {
      this.num = num;

      // 校验手工扣费金额
      if (this.deductionType === 2 && !fix2Reg.test(this.consumerPrice)) {
        this.$toast("请输入正确的消费价格");
        return;
      }

      let memberNumReg = /^1\d{11}$/; // 校验是否为会员号
      if (memberNumReg.test(this.num) && !password) {
        this.getMemberInfo();
        return;
      }
      let params = {
        consumeChannels: "7", // 窗口消费渠道
        num: this.num, // 门票号|微信支付条形码|支付宝付条形码|会员号|项目套餐号
        itemName: this.name, // 项目名称
        itemSkuId: this.skuId, // 项目skuId
        itemSpuId: this.spuId, // 项目spuId
      };
      // 扣费类型(deductionType)：0按次扣费 1计时扣费 2手工扣费
      if (this.deductionType === 1) {
        params.timeType = this.timeType; // 计时类型：开始0 结束1
      } else if (this.deductionType === 2) {
        params.consumerPrice = Math.round(this.consumerPrice * 10000) / 100; // 消费金额
      }
      // 会员密码
      if (password) {
        params.memberPw = password;
      }
      itemWriteoff(params, { catch: false })
        .then((res) => {
          speak(`消费${res.title}`);
          res.title = `消费${res.title}`;
          this.info = res;
          this.success = true;
          this.show2 = true;
          this.passwordFormVisible = false;
          this.countDown();
          this.$refs.card.clear();
          this.consumerPrice = "";
        })
        .catch((err) => {
          this.success = false;
          let errMsg = ""; // 错误消息
          if (err.status === "MEMBER10140") {
            this.getMemberInfo();
            errMsg = err.message;
            speak(`${errMsg}`);
          } else {
            errMsg =
              err.message.indexOf(",") > 0
                ? err.message.substring(0, err.message.indexOf(","))
                : err.message;
            speak(`消费失败，${errMsg}`);
            this.info = {
              title: "消费失败",
              fail: errMsg,
            };
            this.show2 = true;
            this.countDown();
          }
        });
    },
    // 输入支付密码后提交
    onSubmit() {
      this.onOk(this.num, this.password);
    },
    // 通过项目消费编号获取会员信息
    getMemberInfo() {
      itemConsumerMember({ consumeNumber: this.num })
        .then((res) => {
          this.memberInfo = res;
          this.passwordFormVisible = true;
        })
        .catch(() => {});
    },
    // 倒计时
    countDown() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.count--;
          if (this.count === 0) {
            this.clearCount();
          }
        }, 1000);
      }
    },
    // 清除计时
    clearCount() {
      this.show2 = false;
      clearInterval(this.timer);
      this.timer = null;
      this.count = 5;
    },

    // 选择项目
    checkItem(item) {
      this.projectList.forEach((item) => {
        item.checked = false;
      });
      item.checked = true;
      this.skuId = item.skuId;
      this.spuId = item.spuId;
      this.name = item.name;
      this.deductionType = item.deductionType;
      this.show = false;
    },
  },
};
</script>

<style scoped lang="less">
/*本次消费价格*/
.input-box {
  position: relative;
  border: 1px solid @border;
  border-radius: 0.16rem;
  overflow: hidden;
  .close {
    position: absolute;
    top: 0.16rem;
    right: 0.32rem;
    color: #ccc;
  }
  .input {
    border: none;
    height: 0.8rem;
    padding: 0.08rem 0.32rem;
  }
}

/*切换项目箭头*/
.arrow {
  transform: rotateZ(90deg);
  margin-left: 0.08rem;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.48rem;
}
/*切换项目*/
.wrapper {
  padding: 0.32rem;
  max-height: 70vh;
  overflow-y: auto;
}
</style>
