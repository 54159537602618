<template>
  <keep-alive include="ChangeOff">
    <router-view />
  </keep-alive>
</template>

<script>
export default {
  name: "HomeIndex",
  data() {
    return {};
  },
};
</script>

<style scoped></style>
