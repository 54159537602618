/**
 *@name: 信息接口
 */
import http from "../utils/fetch"; // 公共请求方法
const prefix = `/api/report`; // 前缀

/**
 *首页信息接口
 */
// 【yApi】- 首页信息接口 - 云玉峰
export const formFindScenicDailyPersonTotal = (data) => {
  return http.get(`${prefix}/form/find/scenic/daily/person/total`, data);
};
