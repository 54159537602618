<!--扫码和读取身份证-->
<template>
  <div>
    <!--扫码-->
    <header v-if="active !== 0" class="ph-2x pv-4x text-c gray bg-white mt-1x">
      <van-icon name="scan" class="scan" />
      <p class="mb-1x">点击下方按钮使用扫码核销</p>
      <van-button type="danger" round block @click="scan">扫码核销</van-button>
    </header>

    <!--读卡-->
    <div v-if="active != 1" class="ph-2x pv-4x bg-white mt-1x">
      <div class="input-box flex-start mb-4x">
        <input
          v-model="code"
          type="text"
          class="input flex-grow"
          :placeholder="placeholder"
          size="large"
          maxlength="100"
          @keyup.enter="sure"
        />
        <div class="id-card flex-center">
          <van-icon
            v-if="cardAndScan"
            name="scan"
            @click="scan"
            class="font-xxxl primary"
          />
          <van-icon v-else name="idcard" class="font-xxxl primary" />
        </div>
        <van-icon
          name="clear"
          class="font-xxl close"
          v-show="code.length"
          @click="code = ''"
        />
      </div>
      <van-button type="primary" round block @click="sure">确定</van-button>
    </div>
  </div>
</template>

<script>
import { scanQRCode } from "../../../../utils/hardware";

export default {
  name: "ScanAndCard",
  props: {
    // 票号占位符
    placeholder: {
      type: String,
      default: "订单号/票号/手机号/卡号/护照号",
    },
    //1扫码 0读卡
    active: {
      type: Number,
      default: 0,
    },
    //是否开启读卡和扫码
    cardAndScan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // code: "397407863613", // 编号
      // code: "196724352611", // 编号
      code: "", // 编号
    };
  },
  created() {
    try {
      // window.test.optScan("0");
      window.scanCode = this.scanOk;
      window.readIdCard = this.scanIdCard;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    // 扫码
    scan() {
      scanQRCode(this.scanOk);
    },
    scanOk(code) {
      this.code = code;
      this.sure();
    },
    scanIdCard(obj) {
      this.code = JSON.parse(obj).id;
      this.sure();
    },
    // 确定
    sure() {
      if (!this.code) {
        this.$toast("请输入" + this.placeholder);
        return;
      }
      this.$emit("ok", this.code);
    },
    // 清除
    clear() {
      this.code = "";
    },
  },
};
</script>

<style scoped lang="less">
.scan {
  font-size: 2.4rem;
}

.input-box {
  position: relative;
  border: 1px solid @border;
  border-radius: 0.16rem;
  overflow: hidden;
  .close {
    position: absolute;
    top: 0.16rem;
    right: 1.16rem;
    color: #ccc;
  }
  .input {
    border: none;
    height: 0.8rem;
    padding: 0.08rem 0.32rem;
  }
  .id-card {
    height: 0.8rem;
    width: 1rem;
    text-align: center;
    border-left: 1px solid @border;
  }
}
</style>
